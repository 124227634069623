*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
}

body {
  margin: 0;
}

.box {
  padding: 1em;
}

.box-small {
  padding: 10px;
}

.box-scroll {
  padding: 1em 0 1em 1em;
}

/* Our Grid Code */

.app-layout {
  display: grid;
  height: 100vh;
  grid-template-columns: auto auto minmax(800px, 1fr);
  grid-template-rows: auto auto 1fr auto;
  overflow-x: auto;
}

.appbar {
  background-color: #112044;
}
.workspace {
  background-color: #1b2a4e;
  color: #bdc9e8;
  min-width: max-content;
}
.channels {
  background-color: #20325d;
  color: #bdc9e8;
}
.write {
  background-color: #f2f2f2;
}

.appbar {
  grid-column: 2 / 4;
  grid-row: 1;
  border-bottom: 1px solid #384c74;
}

.workspace {
  grid-column: 1;
  grid-row: 1 / 5;
  overflow-y: hidden;
  border-right: 1px solid #384c74;
}

.workspace:hover {
  overflow-y: auto;
}

.workspace:hover {
  overflow-y: auto;
}

.workspace::-webkit-scrollbar-track {
  background: #112044;
}
.workspace::-webkit-scrollbar-thumb {
  background: #aaa;
}
.workspace::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.channels {
  width: 200px;
  grid-column: 2;
  grid-row: 2 / 5;
  overflow-y: hidden;
  padding-right: 0;
  padding-left: 0;
  scrollbar-color: yellow blue;
}

.channels:hover {
  overflow-y: auto;
}

.channels::-webkit-scrollbar-track {
  background: #1b2a4e;
}
.channels::-webkit-scrollbar-thumb {
  background: #aaa;
}
.channels::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.header-panel {
  grid-column: 3;
  grid-row: 2;
  color: #333;
  border-bottom: 1px solid #f2f2f2;
}

.messages {
  grid-column: 3;
  grid-row: 3;
  overflow-y: auto;
}

.write {
  grid-column: 3;
  grid-row: 4;
}

.participant {
  border-radius: 0px;
  display: inline-block;
}

video {
  width: 100%;
  object-fit: contain;
  max-width: 600px;
  display: block;
  margin: 0 auto;
}

.member-app-layout {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr auto;
}

.member-contents-1 {
  grid-column: 1;
  grid-row: 1;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-contents-2 {
  grid-column: 1;
  grid-row: 2;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-action {
  grid-column: 1;
  grid-row: 3;
}

.ui.mini.search.search-box > .ui.left.icon.input > input {
  color: #fff;
  background-color: #1b2a4e;
  width: 250px;
}

.ui.mini.search.search-box > .ui.left.icon.input > i.icon {
  color: #fff;
}

.underline {
  border-bottom: 1px solid currentColor;
  display: inline-block;
  line-height: 1.25;
  font-weight: 700;
  color: #fff;
}

.thin {
  font-weight: 300;
}

.ql-editor {
  min-height: 70px;
}

#emoji-palette {
  max-width: 400px !important;
  top: -40px !important;
  left: 200px !important;
}

#tab-panel {
  max-height: 70px !important;
}

/******     Sidebar     ******/

.ui.icon.menu .item {
  line-height: 40px;
  padding-right: 500px;
  text-align: justify;
  font-weight: 600;
}

.ui.vertical.menu .item::before {
  height: 0px;
}

.ui.labeled.icon.menu .item > .icon:not(.dropdown) {
  float: left;
  margin-right: 12px !important;
}
